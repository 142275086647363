<template lang="pug">
main(v-if='isReady')
  .page(:class='pageClass' style='padding-top: 60px')
    header-component(
      :active-organization='organizationSelected'
      :organizations='organizations'
      :user='$root.user'
      v-on:change-organization='changeOrganizaton'
    )
    .container-fluid
      .row
        #sidebar.col.max-width-sidebar-xl-new(
          v-if='showSideBar || $root.isMobile'
        )
          .sidebar-base(style='margin-left: -15px')
            simple-sidebar(:nav='nav')
              template(v-slot:sidebar-header)
                DropdownSidebar.d-xl-none.mb-4.text-center(
                  :currentOrganization='organizationSelected'
                  :organizations='organizations'
                )
                button.btn.btn-form-submit.mb-3(@click='newEnvelope')
                  icon(name='plus')
                  span.text Novo envelope
              //- template(v-if='showSubscriptionPlan' v-slot:sidebar-footer)
              //-   .simple-sidebar__footer
              //-     h6.section-title Consumo
              //-     .section-content
              //-       .info-block.mb-2
              //-         .icon-block
              //-           icon(:fix-width='true' name='signature')
              //-         .data-block
              //-           router-link(
              //-             to='/business/organizations/settings#plans'
              //-           )
              //-             span.text.d-block.data-block__title
              //-               strong {{ subscriptionPlan.plan.name }}
              //-             small.text.data-block__expiration-date(
              //-               style='color: #555'
              //-             ) Renova em
              //-               | {{ subscriptionPlan.next_recurrence_at | dateTime }}
              //-       .info-block.mb-3(v-if='subscriptionPlan.quota')
              //-         .icon-block
              //-           icon(:fix-width='true' name='paper-plane')
              //-         .data-block
              //-           .progress(style='height: 10px')
              //-             .progress-bar.bg-warning(
              //-               :aria-valuenow='quotaPercentage'
              //-               :style='`width: ${quotaPercentage}%`'
              //-               aria-valuemax='100'
              //-               aria-valuemin='0'
              //-               role='progressbar'
              //-             )
              //-           small.text.data-block__total-shipments {{ subscriptionPlan.quota.envelopes.consumed }}
              //-             |
              //-             | envios de
              //-             |
              //-             | {{ subscriptionPlan.quota.envelopes.available }}
              template(v-slot:sidebar-footer)
                .sidebar-footer
                  plan-info.plan-info
        .col
          .content
            top-page-bar
            router-view
          #backdrop(@click='closeSidebar()')
          footer-component(style='margin: 0 -12px')
  new-envelope-mobile-modal(ref='newEnvelopeMobileModal')
</template>
<script>
import HeaderComponent from '@/ui/organisms/Header.vue';
import FooterComponent from '@/ui/organisms/Footer.vue';
import SimpleSidebar from '@/ui/organisms/SimpleSidebar.vue';
import Icon from '@/ui/atoms/Icon.vue';
import topPageBar from '@/ui/organisms/TopPageBar.vue';
import NewEnvelopeMobileModal from '@/modules/business/components/organisms/modals/NewEnvelopeMobileModal.vue';
import DropdownSidebar from '@/ui/molecules/DropdownSidebar.vue';
import PlanInfo from '@/ui/molecules/PlanInfo.vue';

export default {
  name: 'BusinessLayout',
  components: {
    HeaderComponent,
    FooterComponent,
    SimpleSidebar,
    Icon,
    topPageBar,
    NewEnvelopeMobileModal,
    DropdownSidebar,
    PlanInfo,
  },
  data() {
    return {
      user: '',
      activeOrganization: {},
      sideBar: true,
      isReady: false,
      subscriptionPlan: {},
      currentOrganization: false,
    };
  },
  async created() {
    await this.checkSelectedOrganization();
    await this.$store.dispatch('subscription/getSubscription');
    this.$store.dispatch('onboardings/getOnboardings');
    this.isReady = true;
    this.checkSubscriptionState();
    this.$loading(false);
  },
  methods: {
    checkSubscriptionState() {
      const storage = window.localStorage;
      const redirected = storage.getItem('redirectedToSubscription');
      if (
        this.$store.state.subscription.plan.state === 'uninitialized' &&
        !redirected &&
        this.isOwner
      ) {
        storage.setItem('redirectedToSubscription', true);
        this.$router.push('/account/billing/plan');
      }
    },
    closeSidebar() {
      this.$root.closeSidebar();
    },
    changeOrganizaton(organization) {
      this.$store.dispatch('organizations/setSelected', organization);
    },
    newEnvelope() {
      if (this.$root.isMobile) {
        this.$refs.newEnvelopeMobileModal.$refs.modal.open();
      }
      this.$router.push({ name: 'business.envelopes.new' });
    },
    async checkSelectedOrganization() {
      const organization =
        this.organizationSelected.id ?? this.organizationSelected;
      await this.$store.dispatch('organizations/getItems');
      let selectedOrganization = null;
      if (organization) {
        // Check if selected organization is present on organizations list
        selectedOrganization = this.organizations.find(
          (item) => item.id === organization
        );
      }
      if (!selectedOrganization) {
        // Set first organization as a default organization
        selectedOrganization = this.organizations.find(
          (item) => item !== undefined
        );
      }
      await this.$store.dispatch(
        'organizations/setSelected',
        selectedOrganization
      );
    },
  },
  computed: {
    isOwner() {
      return this.$store.state.organizations.selected.is_owner;
    },
    pageClass() {
      return this.$store.state.page.class;
    },
    showSideBar() {
      return this.$store.state.page.sideBar;
    },
    organizations() {
      return this.$store.state.organizations.items;
    },
    organizationSelected() {
      return this.$store.state.organizations.selected;
    },
    // quotaPercentage() {
    //   return (
    //     (this.subscriptionPlan.quota.envelopes.consumed /
    //       this.subscriptionPlan.quota.envelopes.available) *
    //     100
    //   );
    // },
    // showSubscriptionPlan() {
    //   return this.subscriptionPlan.plan && this.subscriptionPlan.quota;
    // },
    nav() {
      const menu = {
        items: [
          {
            label: 'Início',
            icon: 'house',
            route: {
              name: 'business.dashboard',
            },
          },
          {
            label: 'Envelopes',
            icon: 'envelope',
            route: {
              name: 'business.documents',
            },
          },
          {
            label: 'Armazenamento',
            icon: 'folder',
            route: {
              name: 'business.storage',
            },
          },
          {
            label: 'Verificador',
            icon: 'check',
            auxIcon: 'arrow-up-right-from-square',
            route: {
              name: 'business.checker',
            },
          },
          {
            label: 'Assinatura presencial',
            icon: 'file-user',
            route: {
              name: 'business.envelopes.faceToFaceList',
            },
          },
        ],
      };
      if (!this.$store.state.organizations.personalWorkspace) {
        let showUser = true;
        let showTeams = true;

        if (this.organizationSelected) {
          const organizationSelected = this.organizationSelected;
          const isOwner = organizationSelected.is_owner;
          if (
            !isOwner &&
            organizationSelected.permissions &&
            !organizationSelected.permissions.manage_users
          ) {
            showUser = false;
          }
          if (
            !isOwner &&
            organizationSelected.permissions &&
            !organizationSelected.permissions.manage_teams
          ) {
            showTeams = false;
          }
        }

        if (showUser) {
          /** removido temporariamente */
          // menu[0].items.push(
          //   {
          //     label: 'Usuários',
          //     icon: 'user',
          //     link: '/business/users',
          //   },
          // );
        }

        if (showTeams) {
          /** removido temporariamente */
          // menu[0].items.push(
          //   {
          //     label: 'Times',
          //     icon: 'users',
          //     link: '/business/teams',
          //   },
          // );
        }
      }
      return menu;
    },
  },
  watch: {
    organizationSelected(organization) {
      if (organization.style) {
        const css = `
          .btn-primary {
            background-color: ${organization.style.button_background_color};
            color: ${organization.style.button_text_color};
            border-color: ${organization.style.button_background_color};
          }
          .btn-primary:hover {
            background-color: ${organization.style.button_background_color};
            color: ${organization.style.button_text_color};
            border-color: ${organization.style.button_background_color};
            opacity: 0.9;
          }
          .header {
            background-color: ${organization.style.navbar_background_color};
          }
          .header .header-organization .icon, .header .header-organization .text {
            color: ${organization.style.navbar_text_color};
          }
        `;
        window.document.getElementById('custom-style').innerHTML = css;
      }
    },
  },
};
</script>
<style lang="stylus">
ul.select-organization-menu-list
  .list-item-link
    padding: 0 1rem
    margin: 0
.btn-form-submit
  width: 100%
  .text
    line-height: 1.7em
.sidebar-footer
  .plan-info
    position: inherit
    bottom: 4.688rem
    width: calc(235px - 2.5rem)
@media screen and (max-width: 768px)
  .simple-sidebar__section
    bottom: 15px
@media screen and (max-height: 460px)
  .simple-sidebar-new
    overflow-y: auto
    overflow-x: hidden
</style>
