<template lang="pug">
span
  icon(
    :class='icon.class'
    :name='icon.name'
    v-if='isSandbox'
    v-tooltip:top='"Envelope criado via API em modo Sandbox"'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'IconEnviromentEnvelope',
  components: {
    Icon,
  },
  props: {
    isSandbox: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    icon() {
      return {
        name: 'cube',
        label: 'Sandbox',
        class: 'icon--sandbox',
      };
    },
  },
};
</script>

<style scoped lang="stylus">
.icon--sandbox
  color: #3ECB90 !important
</style>
