<template lang="pug">
div
  .row
    ul#my-tab.nav.nav-tabs(role='tablist')
      li.nav-item(
        :key='tab.id'
        role='presentation'
        v-for='tab in tabs'
      )
        button.nav-link(
          :aria-controls='tab.id'
          :aria-selected='activeTab === tab.id'
          :class='{ active: activeTab === tab.id }'
          :data-bs-target='`#${tab.id}`'
          :id='`${tab.id}-tab`'
          @click='setTab(tab.id)'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) {{ tab.label }}
    #myTabContent.tab-content
      #plan-details.tab-pane.fade(
        :class='{ "show active": activeTab === "plan-details" }'
        aria-labelledby='plan-details'
        role='tabpanel'
      )
        PlanDetails(
          :creditCard='creditCard'
          :plan='currentPlan'
          :planStatus='planStatus'
        )
      #plans.tab-pane.fade(
        :class='{ "show active": activeTab === "plans" }'
        aria-labelledby='plans'
        role='tabpanel'
      )
        MyPlans(
          :lackPlan='lackPlan'
          :plans='plans'
          @togglePlan='changePlanValues'
        )
</template>

<script>
import PlanDetails from './PlanDetails.vue';
import MyPlans from './MyPlans.vue';
import BillingHttp from '@/services/BillingHttp';

export default {
  name: 'PlanPage',
  components: { PlanDetails, MyPlans },
  data() {
    return {
      plans: [
        {
          tag: {
            name: 'PERSONALIZADO',
            icon: 'up-right-and-down-left-from-center',
          },
          title: {
            name: 'Corporativo',
            icon: 'rocket',
          },
          price: null,
          description: `Solução completa para grandes empresas, com máxima flexibilidade e recursos
              para gerenciar grandes volumes de assinaturas`,
          benefits: null,
        },
      ],
      lackPlan: '12',
      creditCard: {},
      tabs: [
        { id: 'plan-details', label: 'Detalhes do plano' },
        { id: 'plans', label: 'Planos' },
      ],
      activeTab: 'plan-details',
    };
  },
  async created() {
    this.$loading(true);
    try {
      await this.initializePage();
      await this.loadData();
      this.setInitialTab();
    } finally {
      this.equalizeCardDescription();
      this.$loading(false);
    }
  },
  mounted() {
    this.equalizeCardDescription();
    window.addEventListener('resize', this.equalizeCardDescription);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.equalizeCardDescription);
  },
  watch: {
    '$route.query.tab': 'setInitialTab',
  },
  beforeRouteUpdate(to, from, next) {
    this.initializePage();
    next();
  },
  computed: {
    currentPlan() {
      return this.$store.state.subscription.plan.plan;
    },
    planStatus() {
      const statusMap = {
        cancelled: {
          name: 'Plano Inativo',
          icon: 'ban',
          class: 'alert-simple-danger',
          type: 'cancelled',
        },
        recurring: {
          name: 'Plano Ativo',
          icon: 'circle-check',
          class: 'alert-simple-success',
          type: 'recurring',
        },
        uninitialized: {
          name: 'Aguardando Pagamento',
          icon: null,
          class: 'alert-simple-warning',
          type: 'uninitialized',
        },
      };
      return statusMap[this.$store.state.subscription.plan.state] || {};
    },
  },
  methods: {
    async initializePage() {
      this.$store.dispatch('page/setTitle', 'Planos');
      this.$store.dispatch('page/setHead', 'Meu Plano');
      this.$store.dispatch('page/setClass', 'account plans');
    },
    async loadData() {
      BillingHttp.supressDefault = true;
      const [plansResult, creditCardResult] = await Promise.allSettled([
        BillingHttp.getPlansList(),
        BillingHttp.subscriptionsCreditCard(),
      ]);
      this.plans =
        plansResult.status === 'fulfilled'
          ? this.formatPlans(plansResult.value.data.data)
          : null;
      this.creditCard =
        creditCardResult.status === 'fulfilled'
          ? creditCardResult.value.data
          : null;
      this.equalizeCardDescription();
    },
    filterAndSortPlans(plans) {
      const planTypes = this.getPlanType();
      return plans
        .filter((item) => planTypes.includes(item.title.name))
        .sort(
          (a, b) =>
            planTypes.indexOf(a.title.name) - planTypes.indexOf(b.title.name) ||
            b.interval - a.interval
        );
    },
    formatPlans(plansData) {
      for (const plan of plansData.reverse()) {
        const dataPlan = {
          id: plan.id,
          interval: plan.interval,
          tag: {
            name: this.getPlanTag(plan.name),
            icon: this.getPlanTag(plan.name, 'icon'),
          },
          title: {
            name: plan.name,
            icon: this.getPlanIcon(plan.name),
          },
          price: this.formatPriceDetails(plan.value_cents),
          description: plan.description,
          benefits: this.formatedResources(
            plan.resources,
            plan.limits,
            plan.features,
            plan.interval
          ),
        };
        this.plans.push(dataPlan);
      }
      return this.filterAndSortPlans(this.plans);
    },
    getPlanType() {
      return ['Básico', 'Essencial', 'Avançado', 'Corporativo'];
    },
    getPlanTag(planName, option = null) {
      const tagPlans = {
        Básico: { tag: 'BÁSICO', icon: 'circle-check' },
        Essencial: { tag: 'POPULAR', icon: 'heart' },
        Avançado: { tag: 'MAIS COMPLETO', icon: 'crown' },
      };
      const plan = tagPlans[planName];
      if (!plan) {
        return option === 'icon' ? null : 'BÁSICO';
      }
      switch (option) {
        case 'icon':
          return plan.icon;
        default:
          return plan.tag;
      }
    },
    getPlanIcon(planName) {
      const iconPlans = [
        { nome: 'Básico', icon: 'leaf' },
        { nome: 'Essencial', icon: 'briefcase' },
        { nome: 'Avançado', icon: 'building' },
      ];
      const plan = iconPlans.find((item) => item.nome === planName);
      return plan ? plan.icon : 'leaf';
    },
    formatPriceDetails(valueCents) {
      const [reais, centavos] = this.formatPrice(valueCents);
      const [monthlyReais, monthlyCentavos] = this.formatPrice(
        this.getMonthlyValue(valueCents)
      );
      return {
        monthly: { number: reais, cents: `,${centavos}/mês` },
        annual: {
          monthly: { number: monthlyReais, cents: `,${monthlyCentavos}/mês` },
          annual: { number: reais, cents: `,${centavos}/ano` },
        },
      };
    },
    formatPrice(price) {
      return (price / 100).toFixed(2).split('.');
    },
    getMonthlyValue(price) {
      return price / 12;
    },
    setTab(tabId) {
      this.activeTab = tabId;
      this.$router.push({ query: { tab: tabId } });
      this.$nextTick(() => {
        this.equalizeCardDescription();
      });
    },
    async setInitialTab() {
      const initialTab = this.$route.query.tab;
      if (initialTab && this.tabs.some((tab) => tab.id === initialTab)) {
        this.activeTab = initialTab;
        await this.initializePage();
      }
    },
    changePlanValues() {
      this.equalizeCardDescription();
      this.lackPlan = this.lackPlan === '12' ? '1' : '12';
    },
    formatedResources(
      resourcesArray = [],
      limitsArray = [],
      featuresArray = [],
      planInterval
    ) {
      const planPeriod = planInterval == '12' ? 'ano' : 'mês';
      const arrayResources = [];
      resourcesArray.forEach((item) => {
        if (item.resource === 'envelopes') {
          arrayResources.push(`${item.quantity} envios por ${planPeriod}`);
        }
      });
      limitsArray.forEach((item) => {
        if (item.limitation === 'storage') {
          arrayResources.push(this.formatStorage(item.quantity));
        } else if (item.limitation === 'users') {
          arrayResources.push(this.formatUsers(item.quantity));
        }
      });
      featuresArray.forEach((item) => {
        if (item.feature === 'whatsapp') {
          arrayResources.push('Notificação por WhatsApp');
        } else if (item.feature === 'integration_api') {
          arrayResources.push('Integração via API');
        }
      });
      return arrayResources;
    },
    formatStorage(quantity) {
      return quantity >= 1000
        ? `${quantity / 1000} Gb de armazenamento`
        : `${quantity} Mb de armazenamento`;
    },
    formatUsers(quantity) {
      return `Até ${quantity} usuários`;
    },
    equalizeCardDescription(retries = 1, interval = 100) {
      setTimeout(() => {
        const attemptEqualization = () => {
          const cardsDescription = Array.from(
            this.$el.querySelectorAll('.card-description')
          ).slice(0, 3);
          const maxHeight = Math.max(
            ...cardsDescription.map((el) => el.offsetHeight)
          );
          if (maxHeight > 0 && this.plans.length > 1) {
            cardsDescription.forEach((el) => {
              el.style.height = `${maxHeight}px`;
            });
          } else if (retries > 0) {
            setTimeout(() => attemptEqualization(), interval);
          }
        };
        attemptEqualization();
      }, '1');
    },
  },
};
</script>
