<template lang="pug">
modal#refresh-developer-client-secret-modal(
  close-button-label='Fechar'
  ref='modal'
  title='Plano Sob Medida'
)
  .hubSpotForm(:id='formContainerId' v-if='isFormLoaded')
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'HubSpotModal',
  props: {
    portalId: {
      type: String,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormLoaded: false,
      formContainerId: 'hubspot-form-container',
    };
  },
  components: {
    Modal,
    Icon,
    ImageLoader,
  },
  created() {
    this.loadHubSpotForm();
  },
  methods: {
    loadHubSpotForm() {
      if (this.isFormLoaded) return;
      this.isFormLoaded = true;

      if (window.hbspt) {
        this.createHubSpotForm();
      } else {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        script.async = true;
        script.defer = true;
        script.onload = this.createHubSpotForm;
        document.body.appendChild(script);
      }
    },
    createHubSpotForm() {
      window.hbspt.forms.create({
        portalId: this.portalId,
        formId: this.formId,
        target: `#${this.formContainerId}`,
        onFormReady: () => {
          this.applyCustomStyles();
          this.populateFormFields();
        },
      });
    },
    applyCustomStyles() {
      const formContainer = document.querySelector(`#${this.formContainerId}`);
      if (!formContainer) return;

      const formElement = formContainer.querySelector('form');
      if (formElement) {
        formElement.style.padding = '15px';
        formElement.style.borderRadius = '8px';
      }

      const inputs = formContainer.querySelectorAll('input, textarea, select');
      inputs.forEach((input) => {
        input.style.padding = '8px';
        input.style.border = '1px solid #ccc';
        input.style.borderRadius = '4px';
        input.style.marginBottom = '10px';
        input.style.width = '100%';
      });

      const submitButton = formContainer.querySelector("input[type='submit']");
      if (submitButton) {
        submitButton.style.backgroundColor = '#19665b';
        submitButton.style.color = 'white';
        submitButton.style.border = 'none';
        submitButton.style.padding = '10px 20px';
        submitButton.style.borderRadius = '5px';
        submitButton.style.cursor = 'pointer';
      }
    },
    populateFormFields() {
      const separateName = this.splitName(this.$root.user.name);
      const fields = [
        { id: `firstname-${this.formId}`, value: separateName.firstname },
        { id: `lastname-${this.formId}`, value: separateName.lastname },
        { id: `email-${this.formId}`, value: this.$root.user.email },
        { id: `phone-${this.formId}`, value: this.$root.user.phone },
        {
          id: `0-2/name-${this.formId}`,
          value: this.$store.state.organizations.selected.name,
        },
      ];

      fields.forEach(({ id, value }) => {
        const field = document.getElementById(id);
        if (field) {
          field.value = value;
          field.dispatchEvent(new Event('input', { bubbles: true }));
        }
      });
    },
    splitName(fullname) {
      fullname = fullname.trim();
      const parts = fullname.split(/\s+/);
      const firstname = parts[0];
      const lastname = parts.slice(1).join(' ');
      return { firstname, lastname };
    },
  },
};
</script>
<style lang="stylus" scoped>
input
    border-radius: 15px
</style>
