import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import './registerServiceWorker';
import AppConfig from '@/config';
import LocalStorage from '@/services/LocalStorage';
import globalStore from './store/index';
// Mixins

// Modules
import ui from '@/modules/ui/index';
import business from '@/modules/business/index';
import account from '@/modules/account/index';
import publicModule from '@/modules/public/index';

import redirects from '@/router/redirects';

// Vue Select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

// Vue Select customized for AOL UI
import AolSelect from '@/ui/atoms/AolSelect.vue';

// Form Validate
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import rules from '@/utils/ValidateRules';

// Mask
import { mask } from 'vue-the-mask';
import VueClipboard from 'vue-clipboard2';
import VueLoading from '@/plugins/Loader';

// Authorization
import UserService from '@/services/AccountHttp';

// filters
import dateFilters from '@/modules/business/common/date.filter';
import phoneFilters from '@/modules/business/common/phone.filter';

import App from './App.vue';
import i18n from './i18n';
import VueGtm from '@gtm-support/vue2-gtm';

const modules = [ui, publicModule, business, account, redirects];

Vue.config.productionTip = false;
i18n.locale = navigator.language || navigator.userLanguage;

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueClipboard);
Vue.use(VueLoading, { text: 'Por favor, aguarde. Carregando informações.' });
Vue.filter('date', dateFilters.dateNormal);
Vue.filter('dateTime', dateFilters.dateTime);
Vue.filter('phone', phoneFilters.formatPhoneNumber);
Vue.component('v-select', vSelect);
Vue.component('aol-select', AolSelect);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.directive('mask', mask);

modules.push({
  name: 'globalStore',
  store: globalStore,
  routes: [],
});

const config = modules.reduce(
  (list, item) => {
    const ret = list;
    ret.routes = ret.routes.concat(item.routes);
    ret.store = Object.assign(ret.store, item.store);
    return ret;
  },
  { routes: [], store: [], nav: [] }
);

Vue.directive('tooltip', (el, binding) => {
  window.jQuery(el).tooltip({
    title: binding.value,
    placement: binding.arg,
    trigger: 'hover',
  });
});

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Vue.filter('fileSize', (bytes) => {
  if (bytes < 1025) {
    return `${bytes.toFixed(1)} Bytes`;
  }
  const kBytes = bytes / 1024;
  if (kBytes < 1025) {
    return `${kBytes.toFixed(1)} Kb`;
  }
  const mBytes = kBytes / 1024;
  return `${mBytes.toFixed(1)} Mb`;
});

Vue.filter('date', (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
});

Vue.filter('time', (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleString('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
  });
});

Vue.filter('dateTime', (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleDateString('pt-BR', {
    dateStyle: 'short',
    timeZone: 'UTC',
  });
});

Vue.filter('utc-date', (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleDateString('pt-BR', {
    dateStyle: 'short',
    timeZone: 'UTC',
  });
});

Vue.filter('fileExtensions', (extensions) => {
  return extensions.reduce((ac, v, index) => {
    ac = ac + `${v}`;
    if (index + 1 < extensions.length - 1) {
      ac = ac + ', ';
    }
    if (index + 1 === extensions.length - 1) {
      ac = ac + ' ou ';
    }
    return ac;
  }, '');
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: config.routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
});

const vuexStore = new Vuex.Store({
  modules: config.store,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    const isAdmin =
      vuexStore.getters['organizations/getIsOwner'] === undefined
        ? true
        : vuexStore.getters['organizations/getIsOwner'];
    if (!isAdmin) {
      return next({ name: 'account.profile' });
    }
    next();
  } else {
    next();
  }
});

if (AppConfig.isGTMEnabled) {
  Vue.use(VueGtm, {
    id: AppConfig.GTMCode,
    defer: false,
    compatibility: false,
    nonce: Math.random().toString(36),
    enabled: AppConfig.isGTMEnabled,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: [],
    trackOnNextTick: false,
  });
}

new Vue({
  async created() {
    this.$loading(true);
    this.isMobile = this.isMobileChecker();
    this.isIos = this.isIosChecker();
    this.isMobileScreen = this.isMobileScreenChecker();
    if (this.$route.name.split('.').shift() === 'public') {
      this.loaded = true;
      return;
    }
    window.addEventListener('resize', () => {
      this.isMobileScreen = this.isMobileScreenChecker();
    });
    let routeToRedirect = null;
    try {
      const result = await UserService.setErrorHandler((error) => {
        throw error;
      }).getUser();
      this.user = result.data;
      if (window.location.pathname === '/') {
        // Go to dashboard page
        routeToRedirect = { name: 'business.dashboard' };
      }
    } catch (error) {
      if (
        error.response?.status === 401 &&
        this.$route.query.action === 'show_recipient_action'
      ) {
        // Go to envelope public page
        routeToRedirect = {
          name: 'public.envelopes.viewer',
          query: this.$route.query.token,
        };
        return;
      }
      if (
        error.response?.status === 401 &&
        this.$route.query.action === 'show_recipient_envelope_completed'
      ) {
        // Go to download public page
        routeToRedirect = {
          name: 'public.envelopes.download',
          query: this.$route.query.token,
        };
        return;
      }
      if (error.response?.status === 401 || error.response?.status === 412) {
        // Go to Authorization Server
        window.location.assign(
          `${AppConfig.authorizationServer}?continue=${window.location}`
        );
      }
    } finally {
      this.loaded = true;
      this.closeSideBarAfterClickRoute();
      if (routeToRedirect) {
        this.$router.replace(routeToRedirect);
      }
    }
  },
  data: {
    user: {
      name: '',
      avatar: '',
    },
    loaded: false,
    isMobile: false,
    isIos: false,
    isMobileScreen: false,
    iconsCache: {},
  },
  methods: {
    async logout() {
      const result = await UserService.logout();
      if (result.status === 204) {
        LocalStorage.set('organization', false);
        window.location.assign(AppConfig.loginPath);
      }
    },
    isMobileChecker() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    isIosChecker() {
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
    isMobileScreenChecker() {
      return window.innerWidth <= 768;
    },
    closeSideBarAfterClickRoute() {
      this.$router.beforeEach((to, from, next) => {
        window.jQuery('#close-sidebar').click();
        next();
      });
    },
    closeSidebar() {
      window.document.getElementById('sidebar').classList.remove('show');
      window.document
        .getElementById('backdrop')
        .classList.remove('modal-backdrop');
      window.document.getElementById('backdrop').classList.remove('show');
    },
  },
  router: router,
  store: vuexStore,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
