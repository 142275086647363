<template lang="pug">
#planinfo__molecule
  .simple-sidebar__section
    h6.section-title Consumo
    .section-content
      .info-block
        .icon-block
          icon(name='signature')
        .data-block
          span.text.d-block.data-block__title {{ planName }}
          .text.data-block__expiration-date {{ expirationText }}
      .info-block.mb-3
        .icon-block
          icon(name='paper-plane')
        .data-block.text_sender
          .text {{ remainingSignatures }} envios restantes
      .button-block
        router-link.btn.btn-thin(
          :to='{ name: "account.billing.plan" }'
          v-if='isOwner'
        ) Ver Detalhes do plano
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import moment from 'moment';

export default {
  name: 'PlanInfo',
  components: {
    Icon,
  },
  computed: {
    planName() {
      return this.$store.state.subscription.plan.plan.name;
    },
    expirationText() {
      const nextRecurrenceAt =
        this.$store.state.subscription.plan.next_recurrence_at;
      const today = moment().startOf('day');
      const formattedDate = moment(nextRecurrenceAt).format('DD/MM/YYYY');
      const nextRecurrenceDate = moment(nextRecurrenceAt).startOf('day');

      if (moment(nextRecurrenceDate).isBefore(today)) {
        return `Expirou em: ${formattedDate}`;
      }
      if (this.$store.state.subscription.plan.state == 'recurring') {
        return `Renova em: ${formattedDate}`;
      }

      return `Expira em: ${formattedDate}`;
    },
    remainingSignatures() {
      if (!this.$store.state.subscription.plan.quota) {
        return 0;
      }
      return (
        this.$store.state.subscription.plan.quota.envelopes.available -
        this.$store.state.subscription.plan.quota.envelopes.consumed
      );
    },
    isOwner() {
      return this.$store.state.organizations.selected.is_owner ? true : false;
    },
  },
};
</script>
